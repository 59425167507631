import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import PageTitle from "../components/pageTitle";
import PageSubTitle from "../components/pageSubtitle";
import Spotify from "../components/spotify";

export default function PodcastPage(): JSX.Element {
  return (
    <Layout>
      <Helmet>
        <title>Polle Kinderboek - Podcast</title>
      </Helmet>
      <PageTitle>Podcast</PageTitle>
      <PageSubTitle> De Introductie </PageSubTitle>
      <Spotify
        spotifyLink=""
        audioPath="/audio/hoorspel/INTRODUCTIE.mp3"
      ></Spotify>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-10 gap-10 child:shadow-lg child:transition child-hover:scale-105 child:bg-yellow-polle child:rounded-lg child:overflow-clip">
        <div>
          <PageSubTitle> Hoofdstuk 1 Deel 1 </PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/7nVABt3E8IQFAXo0L922Fr?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H1_1.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 1 Deel 2</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/2QWAWAD1CVfJOhFT0Wr6J8?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H1_2.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 2 Deel 1</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/5ynb4jXq4LxRf56K9NWaKr?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H2_1.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Meditatie </PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/6JUe7mZLbNP8jIQmjpMadU?utm_source=generator "
            audioPath="/audio/hoorspel/Polle_MEDITATIE.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 2 Deel 2</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/49dP1NRKqZr58mY8yzsrBm?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H2_2.mp3"
          ></Spotify>
        </div>
        <div>
          <PageSubTitle> Hoofdstuk 3 Deel 1</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/10y97WJ3h9OgaeSxSsPf1v?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H3_1.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 3 Deel 2</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/4R3fqy75Ez4endezSPjrxM?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H3_2.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 3 Deel 3</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/0pLGOe0vDP1rIRT1FvTxmj?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H3_3.mp3"
          ></Spotify>
        </div>
        <div>
          <PageSubTitle> Hoofdstuk 4 Deel 1</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/5xP7SgCZpB717NmU0I1ndS?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H4_1.mp3"
          ></Spotify>
        </div>
        <div>
          <PageSubTitle> Hoofdstuk 4 Deel 2</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/4VMJxPgycQ1oCRfA2hiqiw?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H4_2.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 5 Deel 1</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/6tsvACkp5QJ0ECL3Xn2r9I?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H5_1.mp3"
          ></Spotify>
        </div>

        <div>
          <PageSubTitle> Hoofdstuk 5 Deel 2</PageSubTitle>
          <Spotify
            spotifyLink="https://open.spotify.com/embed/track/2gBf5rHfNmlDyB4ynyqvpl?utm_source=generator"
            audioPath="/audio/hoorspel/Polle_H5_2.mp3"
          ></Spotify>
        </div>
      </div>
    </Layout>
  );
}
