import React from "react";

type PageTitleProps = {
  children: React.ReactNode;
  className?: string;
};

export default class PageTitle extends React.Component<{
  className?: string;
  children: React.ReactNode;
}> {
  render() {
    return (
      <h1
        className={`text-5xl md:text-7xl 2xl:text-9xl text-center font-polle text-black m-10 ${this.props.className}`}
      >
        {this.props.children}
      </h1>
    );
  }
}
