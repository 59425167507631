import React from "react";
type SpotifyEmbedProps = {
  spotifyLink: string;
  audioPath: string;
};

export default function SpotifyEmbed({
  spotifyLink,
  audioPath,
}: SpotifyEmbedProps): JSX.Element {
  return (
    <div className="flex flex-col justify-center">
      <audio controls src={audioPath} className="mb-5 bg-blue-polle"></audio>
      <iframe
        src={spotifyLink}
        width="100%"
        height="80"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
}
